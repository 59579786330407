/*
Template Name: Tailwick - Admin & Dashboard Template
Author: Themesdesign
Version: 1.1.0
Website: https://themesdesign.in/
Contact: Themesdesign@gmail.com
File: tailwind scss File
*/

@import "fonts/fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "plugins/gridjs";
@import "plugins/select";
@import "plugins/multi-select";

.group\/menu-link:hover,
.animate {
  animation-iteration-count: 2;
  stroke-dasharray: 10;
}

/* Custom css  */

.text-vertical-menu-item {
  color: rgb(99 104 111) !important;
}
.card .card-body {
  padding: 1.25rem;
  // border: 1px solid rgb(147, 147, 147);
  // width: 900px;
  max-width: 100%;
  // overflow: hidden;
}
.card {
  border-radius: 0!important;
}
.custom-navigation {
  text-align: left;
  background: #e0e0e0;
  width: 200px;
}
.tab-content {
  width: 622px;
  max-width: 100%;
}
.bg-styling {
  border-radius: 0;
  border-top: 1px solid rgb(183, 183, 183);
  border-bottom: 1px solid rgb(183, 183, 183);
  border-left: 1px solid rgb(183, 183, 183);
  border-right: 1px solid rgb(183, 183, 183);
  margin: 0px 0px 0px 0px;
  padding: 12px 20px;
  font-weight: 600;
}
.bg-styling i {
  position: absolute;
  left: 73.5%;
  margin-top: 4px;
}
.show.styling-selected {
  background: rgb(11 109 189) !important;
  color: #ffffff !important;
}
.active {
  .active-arrow {
    position: absolute;
    top: 150px;
    left: 34%;
    height: 4%;
    fill: rgb(211, 211, 211);
  }
}
.active {
  .left-title {
    color: #000000 !important;
    background-color: lightgrey !important;
    border-radius: 0;
    position: relative;
    border-left: 5px solid #0b6dbd;
  }
}
// .tab-pane {
//    border: 1px solid rgb(147, 147, 147);
// }
.active {
  .arrow-right:after {
    content: "";
    position: absolute;
    right: -21px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid lightgray;
  }
}
.custom-css-dropdown .css-1nmdiq5-menu {
  margin-top: -6px!important;
}
@media only screen and (max-width: 1440px) and (min-width: 900px) {
  .bg-styling i {
    position: absolute;
    left: 78% !important;
    margin-top: 4px;
  }
}
.will-change-\[top\2c left\] {
  left: 50%;
  top: 40%!important;
}

/* custom css for us */

.colg-nme-main {
  display: flex;
  // gap: 7rem;
  border: 1px solid #00000024;
  margin: 0px 5px 15px 5px;
}
.colg-nme-ent {
  padding: 12px 10px;
  width: 50%;
  border-right: 1px solid #00000024;
}